import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DateService} from '../services/date/date.service';
import {LanguageService} from '../services/language/language.service';
import {SimulatedDateService} from '../services/simulated-date/simulated-date.service';
import {ReleaseNote, ReleaseNoteDetail} from './cms-types';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private now = DateService.dateTimeToUTCDateTime(this.simulatedDate.getSimulatedDate());

  private currentReleaseNotes$ = this.httpClient
    .get(`${environment.cms.url}/api/release-notes`, {
      headers: {
        Authorization: `Bearer ${environment.cms.token}`
      },
      params: {
        'filters[AktivAb][$lte]': this.now,
        'filters[AktivBis][$gte]': this.now,
        locale: `${this.languageService.lang}-CH`,
        publicationState: this.getPublicationState()
      }
    })
    .pipe(
      map(notes => notes['data'][0]),
      shareReplay(1)
    );

  constructor(
    private httpClient: HttpClient,
    private simulatedDate: SimulatedDateService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute
  ) {}

  public getCurrentReleaseNote(): Observable<ReleaseNote> {
    return this.currentReleaseNotes$;
  }

  public getReleaseNoteById(id: string): Observable<ReleaseNoteDetail> {
    return this.httpClient
      .get(`${environment.cms.url}/api/release-notes/${id}`, {
        headers: {
          Authorization: `Bearer ${environment.cms.token}`
        },
        params: {
          'populate[Langcontent][populate]': '*',
          locale: `${this.languageService.lang}-CH`,
          publicationState: 'preview' // if we know the id, we want to see the preview
        }
      })
      .pipe(map(note => note['data'] as ReleaseNote));
  }

  /** return all past and active release notes, ordered newest first */
  getAllReleaseNotes(): Observable<ReleaseNote[]> {
    return this.httpClient
      .get(`${environment.cms.url}/api/release-notes`, {
        headers: {
          Authorization: `Bearer ${environment.cms.token}`
        },
        params: {
          'filters[AktivAb][$lte]': this.now,
          locale: `${this.languageService.lang}-CH`,
          publicationState: this.getPublicationState(),
          sort: 'AktivAb:desc'
        }
      })
      .pipe(map(note => note['data'] as ReleaseNote[]));
  }

  private getPublicationState() {
    return this.activatedRoute.snapshot.queryParams['debug'] === 'preview' ? 'preview' : 'live';
  }
}
